import React from 'react';
import styled from 'styled-components';
import {
  ACCENT_COLOR,
  BODY_TEXT_COLOR_ON_DARK,
  CONTACT_BACKGROUND,
  HEADING_TEXT_COLOR_ON_DARK,
} from '../style-base';
import {ExternalLink} from './ExternalLink';

const email = 'vladimir@1012.me';

const sendEmailUrl = `mailto:${email}`;
const linkedInUrl = 'https://www.linkedin.com/in/vladimir3l2f';

const resumeUrl =
  'https://e.pcloud.link/publink/show?code=XZt6oWZmoeIrfp9hDXXcVHmSMGxvRHfVET7';

interface Props {
  id?: string;
}

export const ContactSection = (props: Props) => {
  return (
    <Root id={props.id}>
      <Contacts>
        <CallToAction>Let's work together...</CallToAction>
        <SendEmail href={sendEmailUrl} label="Email" />
      </Contacts>
      <Info>
        <Link href={linkedInUrl} label="LinkedIn" />
        <Link href={resumeUrl} label="Resume" />
      </Info>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Contacts = styled.div`
  background: ${CONTACT_BACKGROUND};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 16px;
`;

const CallToAction = styled.h1`
  color: ${BODY_TEXT_COLOR_ON_DARK};
  text-align: center;
`;

const SendEmail = styled(ExternalLink)`
  margin: 24px 32px 24px 32px;
  width: 100px;
  height: 100px;
  background-color: ${ACCENT_COLOR};
  color: ${HEADING_TEXT_COLOR_ON_DARK};
  text-decoration: underline;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px 16px;
  gap: 32px;
`;

const Link = styled(ExternalLink)`
  font-size: large;
`;
