interface Props {
  children: any;
  className?: string;
}

export const Text = ({children, className}: Props) => {
  return (
    <span style={{whiteSpace: 'pre-wrap'}} className={className}>
      {children}
    </span>
  );
};
