import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  key: 'map',
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setKey: (state, action) => {
      let key = action.payload
      state.key = key
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setKey
} = navigationSlice.actions

export default navigationSlice.reducer
