import {Project} from '../model/Project';
import {v4 as uuidv4} from 'uuid';
import screenshot1 from '../assets/sr-app/Untitled1.jpg';
import screenshot2 from '../assets/sr-app/Untitled2.jpg';
import screenshot3 from '../assets/sr-app/coupon with stop date Screenshot 2022-08-09 at 14.47.55.png';

export const shellRechargeApp: Project = {
  id: uuidv4(),
  label: 'Shell Recharge app',
  description: `Shell Recharge is one of the leading apps in Europe for charging electric vehicles at home and on the go. 
I was a senior mobile developer (React Native) focusing on the home charging domain, helping to deliver key features such as charge point onboarding and management.`,
  images: [
    {
      src: screenshot1,
      alt: 'Screenshot 1',
    },
    {
      src: screenshot2,
      alt: 'Screenshot 2',
    },
    {
      src: screenshot3,
      alt: 'Screenshot 3',
    },
  ],
  links: {
    android:
      'https://play.google.com/store/apps/details?id=com.thenewmotion.thenewmotion',
    ios: 'https://itunes.apple.com/nl/app/the-new-motion/id549121633',
  },
};
