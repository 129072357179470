import {GlobalStyle} from './style-css';
import React from 'react';
import styled from 'styled-components';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {MainPage} from './screens/MainPage';
import {AllWork} from './screens/AllWork';
import {NoMatch} from './components/NoMatch';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

export const App = () => {
  const {enableLinkTracking} = useMatomo();

  enableLinkTracking();

  return (
    <Container>
      <GlobalStyle />
      <BrowserRouter>
        {/* Routes nest inside one another. Nested route paths build upon
          parent route paths, and nested route elements render inside
          parent route elements. See the note about <Outlet> below. */}
        <Routes>
          <Route path="/">
            <Route index element={<MainPage />} />

            <Route path="work" element={<AllWork />} />

            {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;
