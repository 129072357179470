const CANYON_BLACK = '#0f0f0f';
const CANYON_YELLOW = '#FBA452';

export const BODY_BACKGROUND = '#FAFAFA';
export const COMPONENT_BACKGROUND = '#FFFFFF';
export const WELCOME_BACKGROUND = '#FAF6F5';
export const CONTACT_BACKGROUND = CANYON_BLACK;

// PRIMARY_COLOR: '#fff',
export const ACCENT_COLOR = CANYON_YELLOW;

export const ACCENT_TEXT_COLOR_HOVER = '#a75f1d';

export const HEADING_TEXT_COLOR_ON_LIGHT = '#434049';
export const BODY_TEXT_COLOR_ON_LIGHT = '#7F7E83';
export const SECONDARY_TEXT_COLOR_ON_LIGHT = '#c0c0c0';

export const HEADING_TEXT_COLOR_ON_DARK = '#FFFFFF';
export const BODY_TEXT_COLOR_ON_DARK = '#BEBDBE';
export const SECONDARY_TEXT_COLOR_ON_DARK = '#D6D6D6';

export const BUTTON_BACKGROUND = CANYON_BLACK;
export const BUTTON_BACKGROUND_HOVER = '#000';

/**
 * default platform font
 * see https://github.com/necolas/normalize.css/issues/665#issue-218860829
 */
const PLATFORM_FONTS = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;

export const FONT_FAMILY = `'Open Sans', sans-serif, ${PLATFORM_FONTS}`;
export const FONT_SIZE = 14;

export const TILE_BORDER_COLOR = '#FFFFFF';
export const TILE_WIDTH = 280;
export const TILE_BORDER_WIDTH = 16;

export const LIST_ITEM_BACKGROUND = '#FFFFFF';
export const LIST_ITEM_HOVER = '#EFEFEF';

export const size = {
  // mobileS: '320px',
  // mobileM: '375px',
  // mobileL: '425px',
  tablet: '768px',
  // laptop: '1024px',
  // laptopL: '1440px',
  // desktop: '2560px'
};

export const device = {
  // mobileS: `(min-width: ${size.mobileS})`,
  // mobileM: `(min-width: ${size.mobileM})`,
  // mobileL: `(min-width: ${size.mobileL})`,
  // phone: `only screen and (max-width: ${size.tablet})`,
  tablet: `only screen and (min-width: ${size.tablet})`,
  // laptop: `(min-width: ${size.laptop})`,
  // laptopL: `(min-width: ${size.laptopL})`,
  // desktop: `(min-width: ${size.desktop})`,
  // desktopL: `(min-width: ${size.desktop})`
  hasCursor: `(pointer: fine)`,
};
