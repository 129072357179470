import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import navigationSlice from './navigationSlice';

export const store = configureStore({
  reducer: {
    navigation: navigationSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware(),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
