import React, {useEffect} from 'react';
import styled from 'styled-components';
import {
  device,
  HEADING_TEXT_COLOR_ON_LIGHT,
  WELCOME_BACKGROUND,
} from '../style-base';
import {useMediaQuery} from 'react-responsive';
import {WelcomeSection} from '../components/WelcomeSection';
import {WorkSection} from '../components/WorkSection';
import {ContactSection} from '../components/ContactSection';
import {ExpertiseSection} from '../components/ExpertiseSection';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

export function MainPage() {
  const {trackPageView} = useMatomo();

  const isTablet = useMediaQuery({query: device.tablet});

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <>
      <Header>
        <NavBar>
          <NavLink href="#welcome-section">About</NavLink>
          <NavLink href="#projects">Work</NavLink>
          <NavLink href="#contact">Contact</NavLink>
        </NavBar>
      </Header>
      <Main>
        <WelcomeSection id="welcome-section" />
        <ExpertiseSection />
        <WorkSection id="projects" />
      </Main>
      <Footer>
        <ContactSection id="contact" />
      </Footer>
    </>
  );
}

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background: ${WELCOME_BACKGROUND};

  @media ${device.tablet} {
    align-items: flex-end;
  }
`;

const NavBar = styled.div`
  width: 240px;
  padding: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media ${device.tablet} {
    width: 480px;
  }
`;

const NavLink = styled.a`
  color: ${HEADING_TEXT_COLOR_ON_LIGHT};
  font-size: 1.1em;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.footer``;
