import {Project} from '../model/Project';
import {connectApp} from './connectApp';
import {freecodecampProjects} from './freecodecampProjects';
import {shellRechargeApp} from './shellRechargeApp';
import {beanToCupApp} from './beanToCupApp';
import {newmotionApp} from './newmotionApp';
import {aviapiratesApp} from './aviapiratesApp';
import {commuterApp} from './commuterApp';
import {multigoApp} from './multigoApp';
import {makiMakiApp} from './makiMakiApp';

export const allProjects: Project[] = [
  shellRechargeApp,
  connectApp,
  beanToCupApp,
  newmotionApp,
  multigoApp,
  // ...freecodecampProjects,
  aviapiratesApp,
  commuterApp,
  makiMakiApp,
];

export const selectedProjects: Project[] = [
  shellRechargeApp,
  connectApp,
  newmotionApp,
];

export {allExpertise} from './expertise';
