import {Expertise} from '../model/Expertise';
import {v4 as uuidv4} from 'uuid';
export const allExpertise: Expertise[] = [
  {
    id: uuidv4(),
    name: 'Software development',
    items: [
      'Agile Methodologies: Kanban, Scrum',
      'Internationalisation and Localisation',
      'Testing; Monitoring',
      'Information Security',
      'Automation',
    ],
  },
  {
    id: uuidv4(),
    name: 'React Native app development',
    items: [
      'JavaScript; TypeScript',
      'React Native; React',
      'Redux; react-navigation; styled-components; React Native Elements; expo',
      'Jest, EsLint, Prettier, Flipper',
    ],
  },
  {
    id: uuidv4(),
    name: 'Android native app development',
    items: [
      'Kotlin; Java',
      'Android SDK',
      'Maps; Location; Push notifications; APIs; Bluetooth/BLE',
      'Firebase',
    ],
  },
];
