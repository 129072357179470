interface Props {
  href: string;
  label: string;
  className?: string;
}

export const ExternalLink = (props: Props) => {
  return (
    <a
      href={props.href}
      className={props.className}
      target="_blank"
      rel="noopener noreferrer">
      {props.label}
    </a>
  );
};
