import React from 'react';
import styled from 'styled-components';
import {
  BODY_TEXT_COLOR_ON_DARK,
  BUTTON_BACKGROUND,
  BUTTON_BACKGROUND_HOVER,
  HEADING_TEXT_COLOR_ON_DARK,
} from '../style-base';
import {ProjectItem} from './ProjectItem';
import {Link} from 'react-router-dom';
import {selectedProjects} from '../data';
import {SectionHeader} from './SectionHeader';

interface Props {
  id?: string;
}

export const WorkSection = (props: Props) => {
  const projects = selectedProjects;

  return (
    <Root id={props.id}>
      <SectionHeader text={'Recent work'} />
      {projects.length === 0 ? (
        <ProjectsPlaceholder>Coming soon...</ProjectsPlaceholder>
      ) : null}

      {projects.length > 0 ? (
        <>
          <Projects>
            {projects.map(project => (
              <ProjectItem key={project.id} project={project} />
            ))}
          </Projects>
          <MoreButton to={'work'}>Show all</MoreButton>
        </>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProjectsPlaceholder = styled.div`
  padding: 44px 0;
`;

const Projects = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 32px;
`;

const MoreButton = styled(Link)`
  width: 200px;
  margin: 60px 0 90px 0;
  padding: 8px 0 12px 0;
  text-align: center;
  border-radius: 16px;
  background: ${BUTTON_BACKGROUND};
  color: ${BODY_TEXT_COLOR_ON_DARK};

  &:hover {
    background: ${BUTTON_BACKGROUND_HOVER};
    color: ${HEADING_TEXT_COLOR_ON_DARK};
  }
`;
