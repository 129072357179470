import {Project} from '../model/Project';
import {v4 as uuidv4} from 'uuid';
import screenshot1 from '../assets/connect-app/unnamed1.webp';
import screenshot2 from '../assets/connect-app/unnamed2.webp';
import screenshot3 from '../assets/connect-app/unnamed4.webp';

export const multigoApp: Project = {
  id: uuidv4(),
  label: 'MultiGO',
  description: 'More info coming soon...',
  images: [
    // {
    //   src: screenshot1,
    //   alt: 'Screenshot 1',
    // },
    // {
    //   src: screenshot2,
    //   alt: 'Screenshot 2',
    // },
    // {
    //   src: screenshot3,
    //   alt: 'Screenshot 3',
    // },
  ],
};
