import React from 'react';
import styled from 'styled-components';
import {ACCENT_COLOR, WELCOME_BACKGROUND} from '../style-base';

interface Props {
  id?: string;
}

export const WelcomeSection = (props: Props) => {
  return (
    <Root id={props.id}>
      <Name>Vladimir Ryabchikov</Name>
      <Role>Software Developer</Role>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100vh;
  background: ${WELCOME_BACKGROUND};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Name = styled.h1`
  text-align: center;
`;

const Role = styled.div`
  text-align: center;
  color: ${ACCENT_COLOR};
  margin-top: -24px;
`;
