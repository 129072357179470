import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ProjectItem} from '../components/ProjectItem';
import {allProjects} from '../data';
import {useMatomo} from '@jonkoops/matomo-tracker-react';

export function AllWork() {
  const {trackPageView} = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  return (
    <Root>
      {allProjects.map(project => (
        <ProjectItem key={project.id} project={project} />
      ))}
    </Root>
  );
}

const Root = styled.main`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  gap: 32px;
  align-items: center;
`;
