import React from 'react';
import styled from 'styled-components';
import {
  COMPONENT_BACKGROUND,
  device,
  TILE_BORDER_COLOR,
  TILE_BORDER_WIDTH,
} from '../style-base';
import {Project} from '../model/Project';
import {Text} from './Text';
import {ExternalLink} from './ExternalLink';

interface Props {
  project: Project;
}

const OptionalExternalLink = ({
  link,
  label,
}: {
  link?: string;
  label: string;
}) => {
  return link ? <ExternalLink href={link} label={label} /> : null;
};

export const ProjectItem = ({project}: Props) => {
  return (
    <Root>
      <Intro>
        <Label>{project.label}</Label>
        <Description>{project.description}</Description>
        {project.links ? (
          <Links>
            <OptionalExternalLink
              link={project.links.android}
              label="Android"
            />
            <OptionalExternalLink link={project.links.ios} label="iOS" />
            <OptionalExternalLink link={project.links.web} label="Link" />
          </Links>
        ) : null}
      </Intro>
      <ImagesContainer>
        <Images>
          {project.images?.map((image, index) => {
            return (
              <Image key={`image-${index}`} src={image.src} alt={image.alt} />
            );
          })}
        </Images>
      </ImagesContainer>
    </Root>
  );
};

const Root = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${COMPONENT_BACKGROUND};
  border-color: ${TILE_BORDER_COLOR};
  border-width: ${TILE_BORDER_WIDTH}px;
  border-radius: 16px;
  border-style: solid;

  @media ${device.tablet} {
    max-width: 1400px;
    flex-direction: row;
  }
`;

const Intro = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    min-width: 360px;
    max-width: 360px;
  }
`;

const Label = styled.h2``;

const Description = styled(Text)`
  font-size: large;
  line-height: 1.5;
`;

const Links = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: auto; // centers this container inside ImagesContainer; https://stackoverflow.com/questions/32528425/centering-items-with-flexbox-and-overflow
`;

const Image = styled.img`
  height: 80vh;
  min-height: 480px;
  max-height: 600px;
`;
