import styled from 'styled-components';

interface Props {
  text: string;
}

export const SectionHeader = ({text}: Props) => {
  return <Root>{text}</Root>;
};

const Root = styled.h2`
  padding: 8px 0;
`;
