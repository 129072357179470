/**
 * see https://material.io/design/typography/the-type-system.html#type-scale
 */
import {createGlobalStyle} from 'styled-components';
import MaterialIcons from './fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2';
import SomeFont from './fonts/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2';
import {
  ACCENT_COLOR,
  ACCENT_TEXT_COLOR_HOVER,
  BODY_BACKGROUND,
  BODY_TEXT_COLOR_ON_LIGHT,
  FONT_FAMILY,
  HEADING_TEXT_COLOR_ON_LIGHT,
} from './style-base';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(${MaterialIcons}) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${SomeFont}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  :root {
  }
  
  body {
    margin: 0;
    background-color: ${BODY_BACKGROUND};
    color: ${BODY_TEXT_COLOR_ON_LIGHT};
    font-family: ${FONT_FAMILY};
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${HEADING_TEXT_COLOR_ON_LIGHT};
  }
  
  a {
    color: ${ACCENT_COLOR};
    text-decoration: none;
  }
  a:hover {
    color: ${ACCENT_TEXT_COLOR_HOVER};
  }
`;
