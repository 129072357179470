import {Project} from '../model/Project';
import {v4 as uuidv4} from 'uuid';
import screenshot1 from '../assets/connect-app/Screenshot_20220309_121214.png';
import screenshot2 from '../assets/connect-app/Screenshot_20220309_121333.png';
import screenshot3 from '../assets/connect-app/Screenshot_20220309_121635.png';

export const connectApp: Project = {
  id: uuidv4(),
  label: 'Connect app',
  description: `A companion app for Connect charge point owners. The first Bluetooth-enabled charge point model developed by the company. 
I was an Android developer and a tech lead for this product, responsible for the delivery of the app and the primary technical point of contact for other teams and an external supplier.`,
  images: [
    {
      src: screenshot1,
      alt: 'Screenshot 1',
    },
    {
      src: screenshot2,
      alt: 'Screenshot 2',
    },
    {
      src: screenshot3,
      alt: 'Screenshot 3',
    },
  ],
  links: {
    android:
      'https://play.google.com/store/apps/details?id=com.thenewmotion.home_charging',
  },
};
