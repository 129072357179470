import {Project} from '../model/Project';
import {v4 as uuidv4} from 'uuid';
export const beanToCupApp: Project = {
  id: uuidv4(),
  label: 'Bean to Cup app',
  description: 'More info coming soon...',
  links: {
    android:
      'https://play.google.com/store/apps/details?id=com.snaprix.beantocup',
    ios: 'https://apps.apple.com/us/app/bean-to-cup/id1671201239',
    web: 'https://bean-to-cup.snaprix.com/',
  },
};
