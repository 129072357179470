import React from 'react';
import styled from 'styled-components';
import {SectionHeader} from './SectionHeader';
import {
  COMPONENT_BACKGROUND,
  TILE_BORDER_COLOR,
  TILE_BORDER_WIDTH,
} from '../style-base';
import {Expertise} from '../model/Expertise';
import {allExpertise} from '../data';

interface Props {
  id?: string;
}

export const ExpertiseSection = (props: Props) => {
  return (
    <Root id={props.id}>
      <SectionHeader text={'Expertise'} />
      <Dataset>
        <Padding />
        {allExpertise.map(expertise => {
          return <ExpertiseElement key={expertise.id} expertise={expertise} />;
        })}
        <Padding />
      </Dataset>
    </Root>
  );
};

const ExpertiseElement = ({expertise}: {expertise: Expertise}) => {
  return (
    <Item>
      <Title>{expertise.name}</Title>
      {expertise.items?.map((row, index) => {
        return <div key={`row-${index}`}>{row}</div>;
      })}
    </Item>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Dataset = styled.div`
  max-width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 240px;
  background-color: ${COMPONENT_BACKGROUND};
  border-color: ${TILE_BORDER_COLOR};
  border-width: ${TILE_BORDER_WIDTH}px;
  border-radius: 16px;
  border-style: solid;
  gap: 6px;
`;

const Title = styled.h3`
  margin: 0;
`;

const Padding = styled.div`
  min-width: 1px;
`;
