import {Project} from '../model/Project';
import {v4 as uuidv4} from 'uuid';
import screenshot1 from '../assets/nm-app/1_post_search_map_1505407088534.png';
import screenshot2 from '../assets/nm-app/2_post_search_list_1505407076344.png';
import screenshot3 from '../assets/nm-app/6_charge_point_details_1505406963207.png';

export const newmotionApp: Project = {
  id: uuidv4(),
  label: 'Newmotion / EV charging app',
  description: `The NewMotion app allowed electric vehicle drivers to find nearby charge points and view their availability and tariffs. 
I was the first Android developer in the team responsible for the entire mobile app development process in an effort to replace a legacy Android application, which led to an app rating increase from 2.5 to 4.2 stars.`,
  images: [
    {
      src: screenshot1,
      alt: 'Screenshot 1',
    },
    {
      src: screenshot2,
      alt: 'Screenshot 2',
    },
    {
      src: screenshot3,
      alt: 'Screenshot 3',
    },
  ],
};
